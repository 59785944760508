import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["publisherId", "notice"]

  reset(event) {
    let that = this
    let newpublisherId = this.publisherIdTarget.value
    if (newpublisherId === '' || this.validatepublisherId(newpublisherId)) {
      alert('Please input your publisher id. And email is invalid here.')
      return
    }

    let confirmMessage = `Are you sure you want to reset your password for account ${newpublisherId}? You will receive an email with new password later.`
    if (confirm(confirmMessage)) {
      that.showNotice('Resetting your password... Please wait.', 'info')
  
      $.ajax({
        url: '/password_resets/using_email',
        method: 'POST',
        dataType: 'json',
        headers: { 'X-CSRF-Token': Rails.csrfToken() },
        data: { publisher_id: newpublisherId },
        error: function(data) {
          that.showNotice(data.responseJSON.message, data.responseJSON.messageType)
        },
        success: function(data) {
          that.showNotice(data.message, data.messageType)
        }
      });
    }
  }
  
  validatepublisherId(publisherId) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(publisherId);
  }
  
  showNotice(context, messageType = 'danger') {
    this.noticeTarget.innerHTML = `
      <div class="alert alert-${messageType} alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        ${context}
      </div>
    `
  }
}