import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "client" ]

  submitAll(event) {
    this.toggleSubmitButton(event.target, true)
    let that = this
    $.ajax({
      url: '/vendor_invoiced_totals/batch_update',
      method: 'POST',
      dataType: 'json',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      data: { data: this.batchUpdateData() },
      error: function() {
        that.toggleSubmitButton(event.target, false)
        alert('Update failed!');
      },
      success: function(data, textStatus, xhr) {
        that.toggleSubmitButton(event.target, false)
        alert('All unlocked values submitted.')
        location.reload();
      }
    });
  }

  batchUpdateData() {
    return this.clientTargets.map(function(client) {
      let inputs = client.getElementsByTagName("input")
      return {
        id: inputs.namedItem('vendor_invoiced_total[invoice_id]').value,
        spend: inputs.namedItem('vendor_invoiced_total[spend]').value,
        makegood: inputs.namedItem('vendor_invoiced_total[makegood]').value
      }
    })
  }

  toggleSubmitButton(element, submiting) {
    if (submiting) {
      $(element).html("<i class='fa fa-spinner fa-spin'></i> Submitting")
    } else {
      $(element).html("Update and Submit All")
    }
  }
}
