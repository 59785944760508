// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('select2')
import 'select2/dist/css/select2.css'

import "bootstrap"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

$(document).on('turbolinks:load', function() {
  'use strict';
  $(function() {
    $('.select2-dropdown').select2();
    $("#partner_profile_tips").modal("show")

    $(".toggle-by-campaign").on('click', function(e) {
      $(e.currentTarget).toggleClass('open')
      let parent = $(e.currentTarget).parents("tr:first")
      let client_id = parent.data("client-id")
      let item = $('.campaign-wrap[data-client-id='+ client_id +']')
      if (item.length > 0) {
        item.remove()
        e.preventDefault()
        e.stopImmediatePropagation()
        return false
      } else {
        parent.after("<tr class='campaign-wrap' data-client-id='"+client_id+"'><td colspan=4>Loading...</td></tr>")
      }
    });

    $(document).on('click', '.toggle-by-daily-campaign', function(e) {
      $(e.currentTarget).toggleClass('open')
      let parent = $(e.currentTarget).parents("tr:first")
      let campaign_id = parent.data("campaign-id")
      console.log(campaign_id)
      let item = $('.daily-campaign-wrap[data-campaign-id='+ campaign_id +']')
      if (item.length > 0) {
        item.remove()
        e.preventDefault()
        e.stopImmediatePropagation()
        return false
      } else {
        parent.after("<tr class='daily-campaign-wrap' data-campaign-id='"+campaign_id+"'><td colspan=4>Loading...</td></tr>")
      }
    });
  });
})